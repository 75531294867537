const texts = {
    common: {
      or: 'Or',
      loading: 'Loading...',
      here: 'here',
      retry: 'Retry',
      search: 'Search',
      selected: 'Selected',
      adUnitName: 'Name',
      countSelected: '({0} Selected)',
      noResultsFound: 'No results found',
      noData: 'No Data',
      emailSupport: 'support@undertone.com',
      yes: 'Yes',
      no: 'No',
      find: 'Find',
      done: 'Done',
      description: 'Description:',
      success: 'Success!'
    },
    buttonsTxt: {
      cancel: 'Cancel',
      save: 'Save'
    },
    passwordCriteria: {
        validLength: '8 characters minimum',
        specialCharacters: 'One special character',
        upperCaseLetters: 'One uppercase character',
        lowerCaseLetters: 'One lowercase character'
    },
    errorMessages: {
      oops: 'Oops.',
      error: 'ERROR',
      somethingWentWrong: 'Something went wrong',
      tryPageRefresh: 'You can try again by refreshing the page',
      somethingWentWrongOnOurEnd: 'Sorry, something went wrong on our end',
      contactAccountManager: 'If the problem persists contact your account manager'
    },
    sendEmail: {
        recipient: 'Recipient',
        recipients: 'Recipients:',
        subject: 'Email Subject',
        message: 'Message',
        send: 'Send!',
        sendTo: 'Send to',
        shareSnippet: 'Share your snippet to a teammate!'
    },
    survey: {
      helpUsImprove: 'Help us improve',
      byAnswering: ' by answering ',
      fiveShort: '5 short',
      questions: ' questions',
      itWillTakeTwoMin: 'It will only take 2 minutes :)',
      bePartOfUdriveDesign: 'Be a part of Udrive’s design!',
      answerNow: 'Answer now',
      remindMeLater: 'Remind me later'
    },
    selectPublisher: {
      pleaseSelectPublisher: 'Please select a publisher',
      pleaseSelectPublisherToStart: 'Please select a publisher to start',
      startTyping: 'Please start typing',
    },
    account: {
      signIn: 'Sign in',
      signOut: 'Sign out',
      createAccount: 'Create Account',
      haveAnAccount: 'Already have an account?',
      agreement: 'I agree to Undertone\'s',
      termsOfService: 'Terms Of Service',
      privacyPolicy: 'Privacy Policy',
    },
    onboarding: {
      steps: {
        enterSite: 'Enter site',
        siteReview: 'Site review',
        adsTxt: 'Ads.Txt',
        accountSetup: 'Account Setup'
      },
      waitingApprovalFrom: 'Waiting for approval from',
    },
    welcomeBack: {
      welcomeBack: 'Welcome Back',
      youAlreadyHaveAccount: 'It looks like you already have an Undertone account!',
      createAnotherAccount: 'Create another account under this email, please contact '
    },
    emailSent: {
      title: 'Email Sent!',
      backToRegister: 'Back to register form',
      sentAnEmailTo: 'We have sent you an email to: ',
      notYourEmail: '(not your email?)',
      checkYourEmail: 'Check your email to activate your account',
      smallTextBottomFirst: 'Didn\'t receive email yet?',
      smallTextBottomSecond: 'Please check your spam folder or  ',
      linkText: 'resend email'
    },
    chartConfig: {
      impressions: 'Impressions',
      revenue: 'Revenue',
      revenueAllSites: 'All Sites',
      revenueTopSites: 'Top 3 Sites',
      topCategories: 'Top Categories',
      topAdunits: 'Top Units',
      topTags: 'Top Tags',
      fillRate: 'fillrate',
      requests: 'requests',
      device: 'Device',
      topDomains: 'Top Sites',
      tagID: 'Tag ID',
      units: 'Units',
      eCPM: 'eCPM',
      fillRateHeaderName: 'Fill Rate',
      category: 'Category',
      trend: 'Trend'
    },
    topCategoriesGrid: {
      category: 'Category',
      trend: 'Trend',
      ctr: 'CTR'
    },
    topAdunitsGrid: {
      adUnits: 'Ad Units',
      impressions: 'Impressions',
      ctr: 'CTR',
      eCPM: 'eCPM',
      revenue: 'Revenue',
      trend: 'Trend',
      placementType: 'Placement Type',
      noData: 'No top units yet'
    },
    trendTooltip: {
      previousDay: 'previous day',
      lastWeek: 'previous week',
      currentWeek: 'same period last week',
      lastMonth: 'previous month',
      currentMonth: 'same period last month',
      previousCustomPeriod: 'previously selected time window',
      trendNA: 'Trend - N/A',
      trendNotAvailable: 'Trend data is not available for the selected dates'
    },
    topTagsGrid: {
      tagId: 'Tag ID',
      units: 'Units',
      eCpm: 'eCPM',
      fillRate: 'Fill Rate'
    },
    confirmationModal: {
      apply: 'Apply',
      cancel: 'Cancel',
      clearAll: 'Clear all',
      delete: 'Delete',
      approve: 'Approve',
      confirmationMessage: 'Are you sure?',
      confirmDeleteAction: 'Confirm delete action',
      bulkDeleteConfirmationMessage: 'You Selected {0} Placement groups.\n Are you sure you want to continue?',
      bulkDeleteHeader: 'Confirm bulk action',
      selectedPlacementGroups: 'Selected',
      save: 'Save',
      successfully: 'Successfully',
      tags: 'Tags:',
      status: 'Status',
      id: ' Placement Group ID:',
      adUnits: 'Ad Units',
      adSlots: 'Ad Slots',
      tagCode: 'Tag code',
      close: 'Close',
      active: 'Active',
      inactive: 'Inactive'
    },
    tooltip: {
      copied: 'Copied!',
      copy: 'Copy'
    },
    adUnits: {
      CPM: 'CPM:',
      updatePlacementGroup: 'Update Placement Group',
      placementGroup: 'Placement Group',
      placementGroups: 'Placement Groups',
      createNewPlacementGroup: 'Create New Placement Group',
      groupName: 'Group name',
      createGroup: 'Create Group',
      typeGroupName: 'Type group name',
      searchByGroupNameorID: 'Search by group name or ID',
      searchByGroupName: 'Search by site name',
      searchByBlockedAdvertiser: 'Search by blocked advertisers',
      missingHb: 'Missing HB settings',
      missingRateCards: 'Missing Rate Card Lines',
      missingHbAndRateCards: 'Missing HB settings and Rate Card Lines',
      addAdunits: 'Add Adunits'
    },
    adSlots: {
      connectExternalAdSlots: 'Connect external publisher ad slot names',
      adSlots: 'Ad Slots',
      add: 'Add',
      adSlotAlreadyExists: 'Ad slot already exists',
      adSlotPlaceholder: 'Paste ad slot here',
      adslotString: 'Adslot string',
      creationDate: 'Creation Date',
      import: 'Import',
      importMsg: 'Make sure that ad slots are placed in the first column of your file',
      adslotInputPlaceholder: 'Paste adslots here',
      serchAdslots: 'Search Adslots',
      addAdslots: 'Add Adslots'
    },
    sspConfigurations:{
      ssptabtitle: 'SSP Configurations',
      impressionurl: 'Send Impression URL in',
      macropricetype: 'Send auction price macro in'
    },
    advancedSettings: {
      oneOffPublisher: 'Set this placement as a One-off placement',
      general: 'General',
      passbackTag: 'Passback tag',
      passbackTagPlaceholder: 'Paste tag here',
      advancedSettings: 'Advanced settings',
      overrideThrottling: 'Override Throttling for this placement',
      ignoreappendpixel: 'Don\'t use System Append Pixel',
      sspconfigtitle: 'SSP Configurations',
      auctionprice: 'Auction price Macro to use',
      winevent: 'Send win event in',
      vidazooplayer: 'Prefer Vidazoo Player'
    },
    fileUpload: {
      import: 'Import',
      attachedFile: 'Attached file:',
      dragFile: 'Drag file',
      or: 'or',
      browse: 'browse',
      replaceFileMessage: 'Only one file can be attached. \n Are you sure you want to replace it?',
      headerText: 'Attention!',
      keepFile: 'No, keep file',
      confirmReplace: 'Yes, replace file',
      uploadingFile: 'Uploading file',
      downloadingFile: 'Downloading file',
      downloading: 'Downloading...',
      attachedManagementFile: 'Attached management file'
    },
    placementGroupGrid: {
      groupsCounter: 'Groups ({0})',
      name: 'Name',
      description: 'Description',
      adUnits: 'Units',
      lastModified: 'Last Modified',
      deliveryType: 'Delivery Type',
      id: 'ID',
      tagType: 'Tag Type',
      status: 'Status',
      autoGeneratedGroup: 'Placement generated by the system',
      oneOffPlacement: 'One-off',
      liDescription: 'LI Description'
    },
    gridNoRowsTemplate: {
      message: 'No Results Were Found',
      actions: 'Actions'
    },
    addSitesModal: {
      addSitesTitle: 'Add sites to your supply',
      addSitesNavItemAdd: 'Add',
      addSitesNavItemImportFile: 'Import file',
      bodyParagraph: 'Send us your URL for approval. For Apps please provide App Store or Google Play URL',
      addButtonText: 'Add Sites',
      cancelButtonText: 'Cancel',
      inputLabelUrl: 'URL*',
      inputLabelNameOptional: 'Name (optional)',
      inputLabelName: 'Name',
      inputPlaceholderUrl: 'Example: publishertimes.com',
      inputPlaceholderName: 'Example: Shusha',
      errorSuggestions: 'Please try again. Suggestions: iTunes.apple.com, play.google.com/store/apps, publishertime.com',
      uploadSitesButton: 'Upload Sites',
      validationMessage: 'Looks Good!',
      warningMessage: 'This Site Does Not Have An Ads.txt File. Please Fix This In Order To Add The Site',
      successMessage: 'Great! Found it!',
      tryAgain: 'Try Again',
      alreadyExist: 'Already Exists!',
      learnMore: 'Learn More',
      copyAdsTxtCodeMessage: 'this code and paste it to the Ads.txt file of your site for a quick validation.'
    },
    widgetsLibraryModal: {
      widgetGallery: 'Widget Gallery',
      widgetGalleryDescription: 'A curated collection of dashboards, charts, admin panels & analytics examples to inspire you in your design process.',
    },
    previewSidebar: {
      preview: 'Preview',
      previewNA: 'Preview N/A',
      viewInNewTab: 'View in new tab',
      selectAdUnit: 'Select Adunit'
    },
    supplySourceGrid: {
      name: 'Name',
      status: 'Status',
      type: 'Type',
      requests: 'Requests',
      impressions: 'Impressions',
      fillRate: 'Fill Rate',
      searchBySites: 'Search by site name'
    },
    supplySourceType: {
      website: 'Website',
      android: 'Android',
      apple: 'IOS App'
    },
    supplySourceSettingsFormatter: {
      settings: 'Settings',
      publisherLevelSettings: 'Publisher Level Settings',
      publisherLevelBlockedAdvertiser: 'Publisher level blocked advertiser. In order to remove it go to publisher settings.',
      publisherLevelBlockedSubCategory: 'Publisher level blocked sub-category. In order to remove it go to publisher settings.',
      publisherLevelBlockedCategory: 'Publisher level blocked category. In order to remove it go to publisher settings.'
    },
    supplySourceSettingsModal: {
      general: 'General',
      performance: 'Performance',
      blockList: 'Block List',
      systemAppend: 'System append Pixel management',
      systemAppendTitle: 'System Append',
      appendPixelChk: 'Don\'t use system append pixel',
      doneButton: 'Done',
      blockAdvertisers: 'Block Advertisers',
      blockCategories: 'Block Categories',
      blockCategoriesDescription: 'Blocked categories will no longer be able to...',
      whatToBlock: 'What do you want to block?',
      numberOfBlockedSubcategories: '{0} Blocked Sub-Categories',
      searchByCategory: 'Search By Category',
      selectAdvertiser: 'Select Advertiser:',
      block: 'Block',
      advertisers: 'Advertisers',
      categories: 'Categories',
      noSelectedAdvertisersMessage: 'You havent selected any advertisers yet',
      edit: 'Edit',
      blocked: 'Blocked',
      unblock: 'Unblock',
      noAdvertisers: 'You haven\'t selected any advertisers yet',
      noCategories: 'You haven\'t selected any categories  yet',
      blockListDescription: 'Create a block list by selecting Advertisers and Categories.',
      bulkBlockListDescription: 'Add blocked advertisers or categories to the selected sites.',
      blockListDescriptionCategories: 'Create a block list by selecting Categories.',
      blockAdvDescription: 'Blocked Advertisers will not be delivered on this site.',
      disabledBlockedAdvertiser: 'The advertiser is already in your block list.',
      bulkBlockMutualValues: 'Showing only the mutual values.',
      confirmBulkActionTitle: 'Confirm bulk action',
      confirmBulkActionBody: 'This action will affect the block list of the sites: \n {0} \n Are you sure you want to continue?',
      confirmBulkActionButtonText: 'Approve',
      videoLimitationTitle: 'Video Limitations',
      adDuration: 'Ad Duration',
      adDurationDescription: 'Select maximum video ad duration',
      skippable: 'Skippable',
      skippableDescription: 'Always support video skip option',
      pubLevelSetting: 'This option is selected on publisher level. In order to change it go to publisher settings.',
      adunitConfigurationsTitle: 'Adunit Configurations',
      interstitialSettingsTitle: 'Interstitial Settings',
      pageGrabberOnChrome: 'Page Grabber on Chrome',
      pageGrabberOnChromeCheckbox: 'Allow PG 1 on Chrome',
      pgActionTrigger: 'PG Action Trigger',
      pgActionTriggersDisabledMsg: 'To enable PG action triggers please set up a placement containing page grabber action ad unit.',
      pgActionTriggerTooltip: 'Allow this trigger on the publishers page',
      videoSnapping: 'Video Snapping',
      videoSnappingDescription: 'When video frame visibility is less than 50% of the frame, the video frame will snap to one side of the screen while it keeps playing.',
      entryDirection: 'Entry direction',
      entryDirectionDescription: 'The video frame will enter from one side of the screen and will stay there static through scrolling.',
      viewExample: 'View example',
      videoSnapMsg: 'Video snap direction when scrolled:',
      videoDisplay: 'Vertical/ horizontal video display on mobile',
      videoDisplayDescription: 'Vertical inline video enables advertisers to use their vertical 9:16 videos as inline\n' +
        'video on mobile only. Opting-out from the vertical version of inline video means you\n' +
        'will only display 16:9 videos on your site.',
      defaultSetting: 'Default setting'
    },
    updatePasswordConfirmation: {
      hyperlinkMessage: 'Continue to Undertone'
    },
    inputFields: {
      emailPlaceholder: 'Email',
      codePlaceholder: 'Enter your one-time code',
      passwordPlaceholder: 'Password',
      qrCodeHeadingPlaceholder: 'Secure Your Account',
      qrCodeTextPlaceholder: 'Scan the QR Code below using your preferred authenticator app and then enter the provided one-time code below.',
      qrVerifyHeadingPlaceholder: 'Verify Your Identity',
      qrVerifyTextPlaceholder: 'Check your preferred one-time password application for a code.'
    },
    loginComponent: {
      subheader: 'To Undertone',
      submitText: 'Login',
      resetPassword: 'Reset password',
      errorMessage: 'Invalid email or password. Please try again',
      keepMeSignedIn: 'Keep me signed in',
      redirectToCreateAccount: 'Don\'t have an account? Create one'
    },
    resetPasswordComponent: {
      header: 'Password Reset',
      subheader: 'Enter your email and a reset link will be sent to you',
      errorMessage: 'Invalid Email. Please try again',
      submitText: 'Send Email',
      backToLogin: 'Back to login screen'
    },
    updatePasswordComponent: {
      title: 'Create New Password',
      passwordPlaceholder: 'Repeat Password',
      submitText: 'Continue',
      errorMessage: 'The password is not strong enough',
      compareErrorMessage: 'The passwords did not match',
      passwordCriterias: [
        'One special character',
        '8 characters minimum',
        'One uppercase character',
        'One lowercase character'
      ],
      newPasswordLabel: 'New Password',
      repeatPasswordLabel: 'Repeat Password',
      checkboxLabel: 'Remember me',
      emptyCodeErrorMessage: 'Please enter the code you got',
      incorrectCodeErrorMessage: 'Incorrect Code, Please enter the code you got'
    },
    resetLinkSent: {
      emailSent: 'Email sent!',
      weSentYouAnEmailTo: 'We have sent you an email to:',
      checkEmailForResetInstructions: 'Check your email for reset instructions',
      submitText: 'Continue',
      inputLabel: 'Enter Code',
      requestMessage: 'back to login',
      linkText: 'let us know',
      validationMessage: 'Invalid code. Please request a new one.',
      emailNotReceived: 'Didn\'t receive email yet?',
      checkYourSpamFolder: 'Please check your spam folder or ',
      resendEmail: 'resend email'
    },
    createPassword: {
      linkExpired: 'Link Expired!',
      backToReset: 'Back to reset password'
    },
    dropdownOptions: {
      yesterday: 'Yesterday',
      lastWeek: 'Last Week',
      currentWeek: 'Current Week',
      lastMonth: 'Last Month',
      currentMonth: 'Current Month',
      custom: 'Custom'
    },
    validateAdsTxtModal: {
      missingAdstxt: "Missing Ads.txt",
      missingAdstxtMessagePart1: "We can't find your Ads.txt code.",
      missingAdstxtMessagePart2: "Please ",
      missingAdstxtMessagePart3: "Copy this code ",
      missingAdstxtMessagePart4: "and paste it to the Ads.txt file in order to continue.",
      codeFound: "Code found!",
      codeFoundApprovedMessage: "Now the site is approved and ready to continue.",
      codeFoundNotApprovedMessage: "Now the site is waiting for internal approval.",
      btnClose: "Close",
      btnCheckAgain: "Check Again"
    },
    payment: {
      payment: 'Payment',
      paymentDetails: 'Payment details',
      paymentHistory: 'Payment history',
      divisionTitle: 'Division:',
      missingContractSignature: 'Sorry, waiting for contract signature'
    },
    analytics: {
      recurrence: 'Recurrence',
      sidebarTitle: 'Reports',
      predefinedReports: 'Predefined Reports',
      newReports: 'New reports',
      dateRange: 'Date Range',
      reportDateRange: 'Report date range',
      runsAtBeginningOf: 'Runs at the beginning of',
      dailyRun: 'every day',
      weeklyRun: 'Monday each week',
      monthlyRun: 'the first day of each month',
      endScheduling: 'End scheduling',
      repeatsEvery: 'Repeats every',
      removeRecurrence: 'Remove Recurrence',
      downloadLinkNotification: 'Due to the requested report size an email will be sent to you with a download link in a few minutes.',
      downloadFailedNotification: 'Download failed, please try again',
      downloadSuccessfulNotification: 'Report downloaded successfully',
      updateSuccessfulNotification: 'Report query updated successfully',
      updateFailedNotification: 'Report query update failed',
      format: 'Format',
      newReportsNotification: 'On November 11th, all existing reports will be replaced with new reports. This will require you to reschedule the reports in the Analytics tab. Please contact your Account Manager with any questions.',
      pleaseNote: 'Please Note',
      presets: {
        today: 'Today',
        yesterday: 'Yesterday',
        last7days: 'Last 7 Days',
        last30days: 'Last 30 Days',
        thisMonth: 'This Month',
        lastMonth: 'Last Month',
        lastWeek: 'Last Week',
        currentWeek: 'Current Week',
        currentMonth: 'Current Month',
        custom: 'Custom'
      },
      recurrenceIntervals: {
        day: 'Day',
        week: 'Week',
        month: 'Month'
      },
      formatTypes: {
        excel: 'Excel',
        csv: 'CSV'
      },
      export: 'Export',
      preview: 'Preview',
      sqlReportQuery: {
        sqlCode: 'SQL CODE',
        preview: 'Preview',
        copyCode: 'Copy code',
        copied: 'Copied',
        cancel: 'Cancel',
        save: 'Save',
        disableSaveMessage: "No changes were made",
        missingParamsMessage: "Please provide :publisherId, :from and :to params",
        confirmationModal: {
          yes: 'yes',
          no: 'no',
          cancelChanges: 'Cancel changes',
          confirmationMessage: "Are you sure you want to cancel the report changes?"
        }
      },
      groupedBy: 'Grouped By',
      days: 'Days',
      months: 'Months',
      groupedByTooltipTxt: 'If the selected date range is less than a month, breakdown by months will include data for the selected dates only.'
    },
    webPreview: {
      zoom: 'Zoom',
      reset: 'Reset',
      pageIndicator: 'Page {0}/{1}'
    },
    exportOptions: {
      print: 'Print',
      web: 'Web',
      excel: 'Excel',
      csv: 'CSV'
    },
    onboardingModal: {
        step_1_1: {
            title: 'Welcome to Undertone',
            domainInfoLabel: 'Let\'s start with one site for approval. please enter your highest traffic domain.\n' +
                'don\'t worry, you can add more sites later.',
            button: 'NEXT',
            urlSite: 'URL of your site',
            validationUrlMessage: 'Please try again. Suggestions: iTunes.apple.com, play.google.com/store/apps, publishertime.com'
        },
        step_2_1: {
            title: 'We are reviewing your site',
            approval1: 'Your site',
            approval2: 'is now waiting for internal approval.',
            approvalMessage: 'This usually takes up to 3 days, but in some cases it can take a bit longer.',
            approvalMessage1: 'We\'ll email you when it\'s ready!',
            info1: 'Not your site?',
            info2: 'Try Again',
            info3: 'Please note that the previous site will be deleted',
            timeFrom: 'Waiting for approval from',
            whatToDo: 'Not sure what to do?',
            visitOur: 'visit our',
            helpCenter: 'help center',
            button: 'CLOSE'
        },
        step_3_1: {
            title: 'Sorry, your site is not approved.',
            domainInfoLabel: 'The team has now reviewed it but unfortunately your site isn\'t\n' +
                'ready to show ads at this time. Try Another Site:',
            button: 'NEXT',
            urlSite: 'URL of your site',
            displayName: 'Display name'
        },
        step_3_2: {
            title: 'Your site is approved!',
            maxRevenue: 'To maximize your revenue, update your ads.txt file',
            pasteCode1: 'Paste this code to your website:',
            pasteCode2: 'not familiar with ads.text? ',
            pasteCode3: 'Learn more',
            checkbox: 'I\'ve pasted the code in my web site. ready for next step.',
            button: 'NEXT',
            copyCode: 'Copy'
        },
        step_3_3: {
            title: 'Amazing! we found it!',
            domainInfoLabel1: 'Your site',
            domainInfoLabel2: 'is now ready to show undertone\'s ads.',
            moreSteps: 'Let\'s get you setup with a few more steps.',
            button: 'OK'
        },
        step_3_4: {
            title: 'We cant find it. try again',
            maxRevenue: 'To maximize your revenue, update your ads.txt file',
            pasteCode1: 'Paste this code to your website:',
            pasteCode2: 'not familiar with ads.text? ',
            pasteCode3: 'Learn more',
            checkbox: 'I\'ve pasted the code in my web site. ready for next step.',
            button: 'TRY AGAIN',
            copyCode: 'Copy'
        },
        step_4_1: {
            title: 'We\'ve created a few tags for you to get started',
            tagInfoLabel: 'paste the tag on the website where you want it to be displayed.\n' +
                'if you don\'t like it, you can create your own tags later.',
            button: 'CONTINUE',
            takeTime: 'It can take xx:xx minutes for the ad to appear on the page.',
            see: 'see',
            guide: 'the code implementation guide for more details'
        },
    },
    ga:{
        category:{
          publisherPlatform:'Publisher platform',
          analyticsModule: 'Publisher platform - Analytics module',
          login: 'Publisher platform - Login screen',
          analyticsModuleFormat: 'Publisher platform - {0}',
          publisherDashboard:'Publisher Dashboard',
          publisherDashboardDatePicker:'Publisher Dashboard - Date Picker',
          publisherDashboardRevenue:'Publisher Dashboard - Revenue',
          publisherDashboardCounters:'Publisher Dashboard - Counters',
          publisherDashboardTopSites:'Publisher Dashboard - Top Sites',
          publisherDashboardTopAdUnits:'Publisher Dashboard - Top Ad Units',
        },
        actions:{
          selectedModuleFormat:'Click {0} module',
          predefineReportFormat:'Select date "{0}"',
          reportSelectedDate:'Select date "{0}"',
          selectOutputFormat:'Select output format {0}',
          clickedResetPassword:'Click on "reset password"',
          clickedLogin:'Click on "login"',
          selectKeepMeSignedIn:'Select "keep me signed in"',
          windowScrollToTop:'Window Scroll to Top',
          windowScrollToBottom:'Window Scroll to Bottom',
          selectFormat:'Select "{0}"',
          showTrendTooltip :'Show Trend tooltip',
          showGraphTooltip :'Show Graph tooltip',
          showPlacementTypeTooltip :'Show Placement Type tooltip',
          changePage:'Change Page',
        }
    }
  };
  export default texts;
